<template>
  <LayoutSuperadmin>
    <template v-slot:title>PRODUCTS</template>
    <div class="product-view h-100 overflow-auto p-2 pl-md-5 pr-md-5">
      <!-- Back to list btn -->
      <GoBackBtn :url="{ name: 'ProductList' }" persistText>
        Back to product list
      </GoBackBtn>
      <!-- Product Details -->
      <AppLoading fillSpace v-if="loading"></AppLoading>

      <div v-else class="my-5 d-flex align-items-center justify-content-center">
        <!-- Product General -->
        <div class="product-view-card row flex-grow-1">
          <div class="col-12 col-md-3">
            <div class="w-100 position-relative">
              <!-- Media  -->
              <AppMediaViewer
                aspect-ratio="1/1"
                ref="mediaFile"
                :src="displayImage"
                :play="videoPlaying"
                @click="videoPlaying = false"
              />
              <!-- video overlay -->
              <div
                v-if="isVideo(displayImage) && !videoPlaying"
                class="
                  product-view-card-play
                  w-100
                  h-100
                  d-flex
                  align-items-center
                  justify-content-center
                "
                @click="videoPlaying = true"
              >
                <AppIcon hoverable name="play_filled" size="100"></AppIcon>
              </div>
            </div>
          </div>
          <div class="mt-5 mt-md-0 col-12 col-md-6">
            <h6 class="m-0 text-gray">PRODUCT</h6>
            <h2 class="mb-0 text-uppercase">{{ product.name }}</h2>
            <p class="mt-1">{{ product.description }}</p>
            <h6 class="m-0 text-gray">SUPPLIER</h6>
            <p class="font-weight-bold">{{ product.user | userCompany }}</p>
            <!-- Variant options -->
          </div>
        </div>
      </div>

      <!-- Related Products -->
      <div v-if="!loading" class="product-view-related-products m-auto">
        <div class="p-3" style="border: 1px solid #bdbdbd">
          <!-- Product Organization -->
          <div class="mt-3">
            <h6 class="m-0">ORGANIZATION</h6>
            <div class="row mt-3">
              <div class="col">
                <AppTextLabeled label="Category">
                  <span v-if="product.category">
                    {{ product.category.category }}
                  </span>
                  <span v-else> Uncategorized </span>
                </AppTextLabeled>
              </div>
              <div class="col pl-5">
                <AppTextLabeled label="Tags">
                  <span v-if="product.tags && product.tags.length > 0">
                    {{ product.tags.map((tag) => tag.name).join(', ') }}
                  </span>
                  <span v-else> Uncategorized </span>
                </AppTextLabeled>
              </div>
            </div>
          </div>
          <!-- price range -->
          <div class="mt-5">
            <div class="row">
              <div class="col">
                <h6 class="m-0">PRICE RANGE</h6>
              </div>
              <div class="col pl-5">
                <h6 class="m-0">INVENTORY</h6>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="row">
                  <div class="col-sm-4 col-md-2">
                    <AppTextLabeled label="Min">
                      ${{ product.price_start || product.price_fixed }}
                    </AppTextLabeled>
                  </div>
                  <span class="col-1"> - </span>
                  <div class="col-sm-4 col-md-2">
                    <AppTextLabeled label="Max">
                      ${{ product.price_end || product.price_fixed }}
                    </AppTextLabeled>
                  </div>
                </div>
              </div>
              <div class="col pl-5">
                <!-- INVENTORY -->
                <div class="row">
                  <div class="col-sm-4 col-md-2">
                    <AppTextLabeled label="SKU">
                      {{ product.sku }}
                    </AppTextLabeled>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PURCHASE QUANTITY OPTION -->
          <div
            v-if="product.quantities && product.quantities.length > 0"
            class="mt-5"
          >
            <h6 class="m-0">PURCHASE QUANTITY OPTION</h6>
            <div class="row mt-3">
              <div
                v-for="(quantity, index) in product.quantities"
                :key="`option-${index}`"
                class="col-sm-6 col-lg-3 mb-3"
              >
                <AppTextLabeled label="Option">
                  <span>
                    {{
                      `${quantity.quantity.name} = ${quantity.conversion} pcs`
                    }}
                  </span>
                </AppTextLabeled>
              </div>
            </div>
          </div>
          <!-- VARIANTs -->
          <div
            v-if="product.variants && product.variants.length > 0"
            class="mt-5"
          >
            <h6 class="m-0">PRODUCT VARIANTS</h6>
            <div class="row mt-3">
              <div
                v-for="(variants, name) in generateVariants(
                  product.variants,
                  true
                )"
                :key="`variant-${name}`"
                class="col-sm-6 col-lg-3 mb-3"
              >
                <AppTextLabeled label="Option">
                  {{ name }} - {{ variants.join(', ') }}
                </AppTextLabeled>
              </div>
            </div>
          </div>
          <!-- VARIANTs PRICING -->
          <div v-if="product.variants && product.variants.length > 0">
            <div class="mt-5">
              <h6 class="m-0">VARIANTS, INVENTORY, PRICING</h6>
              <div class="row mt-3">
                <div class="col-12">
                  <table class="w-100 table table-hover">
                    <thead>
                      <tr>
                        <th>Variant</th>
                        <th>Price</th>
                        <th>Inventory</th>
                        <th>SKU</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(quantity, index) in product.quantities"
                      :key="`pricing-quantities-${index}`"
                    >
                      <tr>
                        <td
                          class="table-active text-capitalize font-weight-bold"
                          colspan="4"
                        >
                          {{ quantity.quantity.name }}
                        </td>
                      </tr>
                      <tr
                        v-for="(variant, index) in product.variants.filter(
                          (vrnt) =>
                            vrnt.quantity_option === quantity.quantity.name
                        )"
                        :key="`variant=pricing-${index}`"
                      >
                        <td>
                          <span class="mr-1 d-inline-block">
                            <span
                                v-for="(detail, index) in generateVariant(variant)"
                                :key="`variant-detail-${index}`"
                            >
                              {{ detail }} /
                            </span>
                          </span>
                        </td>
                        <td>$ {{ variant.price_fixed }}</td>
                        <td>{{ variant.stocks }}</td>
                        <td>{{ variant.sku }}</td>
                      </tr>
                    </tbody>

                    <tbody
                        v-for="(variant, index) in product.variants.filter( vrnt =>
                          !product.quantities.map( (q) => {
                            return q.quantity.name
                          }).includes(vrnt.quantity_option)
                        )"
                        :key="`variant=pricing-${index}`"
                    >
                    <tr v-if="!product.quantities.length > 0">
                      <td>
                        <span class="mr-1 d-inline-block">
                          <span
                              v-for="(detail, index) in generateVariant(variant)"
                              :key="`variant-detail-${index}`"
                          >
                            {{ detail }} /
                          </span>
                        </span>
                      </td>
                      <td>$ {{ variant.price_fixed }}</td>
                      <td>{{ variant.stocks }}</td>
                      <td>{{ variant.sku }}</td>
                    </tr>
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppBtn
          class="my-3"
          type="submit"
          @click="
            $router.push({ name: 'ProductEdit', params: { id: product.id } })
          "
          >EDIT ITEM</AppBtn
        >
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
import Products from '@/shared/api/Products';
import fileExtensionChecker from '@/shared/utils/fileExtensionChecker';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppLoading from '@/shared/elements/AppLoading.vue';
import { required } from '@/shared/utils/validation';
import LayoutSuperadmin from '../../../../components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
import AppTextLabeled from '../../../../shared/elements/AppTextLabeled.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';

import {
  generateVariant,
  generateVariants,
  filterApIVariant,
} from '@/shared/utils/variantsHelper';

export default {
  name: 'VendorProductView',

  components: {
    AppIcon,
    GoBackBtn,
    AppMediaViewer,
    AppLoading,
    LayoutSuperadmin,
    AppTextLabeled,
    AppBtn,
  },

  data() {
    return {
      validations: { required },
      addToCart: false,
      addToCartItem: null,
      cartForm: {
        thumbnail: '',
        product_id: '',
        product_variant_id: '',
        product_name: '',
        quantity: 1,
        quantity_option: null,
        type: 'Materials & Suppliesa',
        note: '',
      },
      loading: true,
      displayImage: '',
      videoPlaying: false,
      filePage: 1,
      product: {},
    };
  },

  watch: {
    addToCart(val) {
      if (val) {
        this.addToCartItem = this.createProductObject();
      }
    },
    $route: {
      handler() {
        this.getProduct();
      },
      deep: true,
    },
  },

  methods: {
    ...fileExtensionChecker,
    filterApIVariant,
    generateVariant,
    generateVariants,
    async getProduct() {
      const id = this.$route.params.id;
      this.loading = true;
      this.product = (await Products.getProduct(id)).data;
      this.loading = false;
      const primaryImg = this.product.files.find(
        (img) => img.type === 'primary_image'
      );

      if (this.product.files.length > 0) {
        const thumbnail =
          primaryImg >= 0
            ? primaryImg.filename
            : this.product.files[0].filename;
        this.setDisplayImage(thumbnail);
        this.cartForm.thumbnail = thumbnail;
      } else {
        this.setDisplayImage('');
      }
      this.cartForm.product_id = this.product.id;
      // this.cartForm.quantity = this.product.stocks;
      this.cartForm.product_name = this.product.name;
    },
    setDisplayImage(path) {
      this.displayImage = path;
    },
    buyNow() {
      const form = this.createProductObject();
      this.$store.dispatch('cart/addToCheckout', form);
      this.$router.push({ name: 'OrderCheckout' });
    },
    createProductObject() {
      const variant_id = this.cartForm.product_variant_id;

      return {
        ...this.cartForm,
        quantity_id: this.cartForm.quantity_option?.id,
        product_quantity_id: this.cartForm.quantity_option?.id,
        variant_name: this.product?.variants.find(
          (variant) => variant.id === variant_id
        )?.name,
      };
    },
  },

  filters: {
    userCompany(value) {
      if (value && value.company && value.company.name) {
        return value.company.name;
      } else {
        return 'N/A';
      }
    },
  },

  mounted() {
    this.getProduct();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
@import '@/scss/theme/_border';

.product-view {
  &-back-btn {
    cursor: pointer;
  }
  &-divider {
    max-width: 1000px;
  }
  &-card,
  &-related-products {
    max-width: 1000px;
  }
  &-card {
    img,
    video {
      aspect-ratio: 1/1;
      width: 100%;
    }
    video {
      cursor: pointer;
    }
    &-play {
      position: absolute;
      margin: auto;
      top: 0;
    }
    &-images {
      &-back {
        position: absolute;
        left: 0;
      }
      &-next {
        position: absolute;
        right: 0;
      }
    }

    &-image {
      width: 10%;

      &:hover {
        @extend .border;
        @extend .border-1;
        @extend .border-primary;
      }
    }
  }
}
</style>
